.match-history::-webkit-scrollbar {
  width: 2px;
}

.match-history::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
}

.match-history::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.5);
}

.match-history::-webkit-scrollbar-corner {
  background-color: transparent;
}
