#champions::-webkit-scrollbar {
  width: 4px;
}

#champions::-webkit-scrollbar-thumb {
  background-color: #fcb8c8;
  border-radius: 4px;
}

#champions::-webkit-scrollbar-track {
  background-color: #c1f4f5;
}

#champions::-webkit-scrollbar-corner {
  background-color: #c1f4f5;
  border-radius: 4px;
}
