/* CSS Reset Style Boilerplate */
*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  /* Color pallette */
  --color-rich-black: #0d1b2a;
  --color-gray: #bec9cb;
  --color-tiffany-blue: #90efdd;
  --color-snow-white: #fcf7f8;
  --color-teal: #36969a;
  --color-red: #a857519d;
  --color-darkgrey: #cfcfcf;
  --color-darkteal: #25676a;
  --color-darkestteal: #102c2d;
  --color-cherry-blossom: #fab2c3;
}

html,
body {
  overflow-x: hidden;
  margin: 0;
  background-color: #f4fafa;
}

#root {
  height: 100vh;
  width: 100vw;
}

body {
  text-align: left;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
}
/* End  */
